body.pubmed-theme {
    min-width: 835px;
    overflow-x: auto;
    overflow-y: hidden;
}

html, body {
  margin: 0;
  padding: 0;
}

#root {
    height: calc(100% - var(--Footer--size-height));
}

.pubmed-theme {
  --pf-global--FontSize--xs: 12px;
  --pf-global--FontSize--sm: 12px;
  --pf-global--FontSize--md: 12px;
  --pf-global--FontSize--lg: 12px;
  --pf-global--gutter: 0.5rem;

  --NavBar--gutter-horizontal: 20px;
  --NavBar--gutter-vertical: 0px;
  --NavBar--brand-size: 30px;

  --Footer--size-height: 30px;
  --Footer--base-color: #767676;
  --Footer--base-color-dark: #494949;

  --modal--inner-pading: 20px;
}

.pubmed-theme header.pf-c-page__header {
    min-height: 30px;
    height: 30px;
}

.pubmed-theme section.pf-c-page__main-section {
    padding-top: 15px;
    padding-bottom: 0;
}

.pubmed-theme .context-area {
    min-height: 300px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.pf-c-page__sidebar-body{
  padding-top: 0 !important;
  padding-bottom: 0;
}

#page-sidebar{
  overflow-y: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#tab-analysis-section.pf-c-tabs .pf-c-tabs__list .pf-c-tabs__item > button::-moz-focus-inner,
#PlotContainer button::-moz-focus-inner {
  border: 0 !important;
}
#tab-analysis-section.pf-c-tabs .pf-c-tabs__list .pf-c-tabs__item > button,
#PlotContainer button {
  outline:none !important;
}
#tab-analysis-section.pf-c-tabs .pf-c-tabs__list .pf-c-tabs__item:first-child > button {
  border-left: 1px solid #ccc;
}

#tab-analysis-section.pf-c-tabs .pf-c-tabs__list .pf-c-tabs__item:last-child > button {
  border-right: 1px solid #ccc;
}

#tabs-container .pf-c-tab-content{
  height: calc( 100% - 35px );
}

.pf-c-button.pf-m-tertiary{
  color: #87868a !important;
}
.pf-c-button.pf-m-tertiary::after{
  color: #cccccc !important;
  border-color: #cccccc !important;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.ReactCollapse--content {
}


.highligt-text{
  border-radius: 2px;
  box-shadow: 0 0 2px 1px #dedede;
  padding-right: 2px;
  padding-left: 2px;
}

.tapSection::-webkit-scrollbar,
.paperViewer::-webkit-scrollbar,
.ActivityConsole::-webkit-scrollbar{
  width: 0px;
  background: transparent;
}

@-moz-document url-prefix() {
  div, .ActivityConsole, .tapSection, .paperViewer{
    overflow: -moz-scrollbars-none !important;
  }
}

.pubmed-theme #main-nav {
  background-color: var(--pf-global--primary-color--dark-100);
  padding: var(--NavBar--gutter-vertical) var(--NavBar--gutter-horizontal);
}
.pubmed-theme #main-nav .pf-c-page__header-tools .pf-c-nav .pf-m-inline li{
  margin-right: 10px;
}
.pubmed-theme #main-nav .pf-c-page__header-tools .pf-c-nav .pf-m-inline li button{
  padding: 0 10px;
}

.pubmed-theme #main-nav button{
  color: #ededed;
  font-weight: bold;
  background-color: transparent;

}
.pubmed-theme #main-nav button:after{
  border: none;
}

.pubmed-theme #main-nav button:hover,
.pubmed-theme #main-nav button:active,
.pubmed-theme #main-nav button:visited
{
  text-decoration: underline;
}

.pubmed-theme footer{
  background-color: #eeeeee;
  height: var(--Footer--size-height);
  overflow: hidden;
}

.pubmed-theme footer > div.footer-section{
  color: black;
  width: 35%;
  float: left;
  padding: 5px 20px;
}

.pubmed-theme footer > div.footer-section.middle{
  width: 30%;
  text-align: center;
}
.pubmed-theme footer > div.footer-section.links{
  text-align: right;
}

.pubmed-theme footer > div.footer-section.links .pf-m-inline{
  float: right;
}

.pubmed-theme footer > div.footer-section.links .pf-m-inline a{
  color: black;
}
.pubmed-theme footer > div.footer-section.links .pf-m-inline a:hover,
.pubmed-theme footer > div.footer-section.links .pf-m-inline a:active,
.pubmed-theme footer > div.footer-section.links .pf-m-inline a:focus
{
  color: var(--Footer--base-color-dark);
}

.pubmed-theme .pf-c-login__container footer{
  background-color: transparent;
}

.pubmed-theme .pf-c-backdrop{
  -webkit-backdrop-filter: none !important;
  backdrop-filter: none !important;
}

.pubmed-theme .back-in-page{
  color: #a0a0a0;
}
.pubmed-theme .back-in-page:hover,
.pubmed-theme .back-in-page:active,
.pubmed-theme .back-in-page:focus
{
  color: #5d5d5d;
  text-decoration: none;
}

#citations {
  position:relative;
  top: 75px;
  height: 0px;
  z-index:1000;
  font-family: var(--pf-global--FontFamily--sans-serif);
  color: var(--pf-global--Color--100);
  text-rendering: optimizeLegibility;
  visibility: hidden;
}

#legend-svg {
  width: 70px;
}

.pubmed-theme .pf-c-modal-box{
  padding: 5px;
}
.pubmed-theme .pf-c-modal-box .pf-c-title{
  padding-left: var(--modal--inner-pading);
  padding-top: var(--modal--inner-pading);
}
.pubmed-theme .pf-c-modal-box__body{
  margin-right: 0 !important;
  padding: var(--modal--inner-pading) calc( var(--modal--inner-pading) + 5px ) var(--modal--inner-pading) var(--modal--inner-pading);
}

.pubmed-theme .pf-c-modal-box > .pf-c-button{
  top: 5px !important;
  right: 2px !important;
}

strong.label-text{
  color: #555555;
}

.item-strong-higthlight strong{
  border-radius: 2px;
  box-shadow: 0 0 2px 1px #eaeaea;
  padding-right: 2px;
  padding-left: 2px;
  background-color: #eaeaea;
}

/* This breaks the responsive design, but we are currently disabling it on the mobile */
.scicarta-navbar {
  --pf-c-page__header-brand--md--PaddingRight: 0;
  --pf-c-page__header-nav--lg--MarginLeft: 0;
  grid-template-columns: auto 1fr auto !important;
}

.scicarta-navbar .pf-c-page__header-nav {
  margin-bottom: var(--pf-c-page__header-tools--MarginBottom);
  margin-top: var(--pf-c-page__header-tools--MarginTop);
  grid-column: 2/3;
  grid-row: 1/2;
  background-color: transparent;
}

.scicarta-navbar .pf-c-page__header-tools {
  grid-column: 3/4;
}

/* Fixing width on search form */
.scicarta-search div.pf-c-form__group {
  --pf-c-form--m-horizontal__group-label--md--GridColumnWidth: 65px !important;
  --pf-c-form--m-horizontal__group-label--md--PaddingTop: 8px;
}

#source-selector .tagify {
  --tag-pad: 0.2em;
  height: 31px;
}

#source-selector div.pf-c-form__group {
  --pf-c-form--m-horizontal__group-label--md--GridColumnWidth: 2px !important;
}

#source-selector .tagify__tag:only-of-type .tagify__tag__removeBtn{
  display: none;
}

.tags-look .tagify__dropdown__item{
  display: inline-block;
  border-radius: 3px;
  padding: .3em .5em;
  border: 1px solid #CCC;
  background: #F3F3F3;
  margin: .2em;
  font-size: .85em;
  color: black;
  transition: 0s;
}

.tags-look .tagify__dropdown__item--active{
  color: black;
}

.tags-look .tagify__dropdown__item:hover{
  background: lightyellow;
  border-color: gold;
}
